import React from "react";
import { textContent, titleContent } from "../../Style";

export default function ValuesDrivenEnterprise() {
  return (
    <div className="w-full h-fit p-10 flex flex-row flex-wrap items-center gap-10 justify-center">
      <div className="relative mobile:hidden left-0 largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
        <div className="absolute left-[-100px] top-10  bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
          <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
        </div>
      </div>

      <div className="flex flex-col mobile:items-center ipadMin:items-center laptop:w-[65%] largeScreen:w-[65%] ipadMax:w-[65%] w-full">
        <div className={`text ${titleContent} font-[200]`}>
          <span className="text font-semibold">Values</span> Driven{" "}
          <span className="text font-semibold">Enterprise</span>
        </div>

        <div className={`px-4 ${textContent} text`}>
          At Theja Technologies we prioritizes and integrates its core values
          into all aspects of its operations, culture, and decision-making
          processes. Rather than solely focusing on profit maximization.
        </div>
      </div>
      <div className="laptop:w-[30%] largeScreen:w-[30%] ipadMax:w-[30%] w-fit px-10">
        
        <div className="relative mobile:hidden right-[-20px] largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
          <div className="absolute right-0  bg-[#5BD02433]  rounded-full w-[40px] h-[40px]"></div>
        </div>

        <img
          src={require("../../assets/whatWeDo/group-people-working-out-business-plan-office.png")}
          alt="business discusison"
          srcSet=""
        />

<div className="relative mobile:hidden left-[-20px] largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
          <div className="absolute left-0  bg-[#5BD02433] rounded-full w-[40px] h-[40px]"></div>
        </div>

      </div>
    </div>
  );
}
