import React from "react";
import { bg_color_green, textContent, titleContent } from "../../Style";
import { ViewOurWorks, WhatWeDoButton } from "../../buttons/Buttons";

export const Home = () => {
  
  return (
    <div
      className={`${bg_color_green}  w-full h-fit text-white flex flex-col gap-10`}
    >
      <div className="cssanimation sequence fadeInBottom w-full h-fit flex flex-row  flex-wrap justify-center items-center px-8 p-4 pt-0">
        <div className="w-fit laptop:max-w-[65%] largeScreen:max-w-[63%] text-left min-h-[500px] h-fit flex flex-col justify-center gap-10">
          <div
            className={`${titleContent} mobile:text-center w-full  ipadMax:text-center ipadMin:text-center font-[200] mobile:text-center`}
          >
            <span className="font-semibold">Designing</span> a better <br /><span className="font-semibold">World</span> today
          </div>
          <div
            className={`${textContent} tracking-wider mobile:text-center w-full  ipadMax:text-center ipadMin:text-center mobile:text-center`}
          >
            Welcome to our world of great customer service. Together lets embark
            on a remarkable journey where dreams become tangible realities
          </div>
          <div className="h-fit w-full flex flex-row flex-wrap mobile:justify-center ipadMin:justify-center ipadMax:justify-center gap-10">
            <WhatWeDoButton />
            <ViewOurWorks />
          </div>
        </div>
        <div className=" mobile:w-[100%] w-[320px]  laptop:w-[300px] ipadMin:w-[200px] grid place-items-center ipadMax:w-[250px]  largeScreen:w-[450px] ">
          <img className="ml-[-50px] laptop:scale-[1.4] largeScreen:scale-[1.4]"
            src={require("../../assets/Surface Laptop Studio.png")}
            alt=""
            srcSet=""
          />
        </div>
        <div className="relative mobile:hidden right-[-72px] largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
          <div className="absolute right-0   border-[24px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]">
          </div>
          <div className="absolute mt-40 right-5 border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]"></div>
        </div>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 80 1440 180"
        className="mt-[-8%] ipadMax:mt-[-6%] largeScreen:mt-[0%] "
      >
        <path
          fill="#ffffff"
          className=" bg-white"
          fillOpacity="100"
          d="M0,192L48,208C96,224,192,256,288,250.7C384,245,480,203,576,165.3C672,128,768,96,864,85.3C960,75,1056,85,1152,112C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
    </div>
  );
};
