import React from "react";
import { textContent, titleContent } from "../Style";
import { Header } from "./header/Header";
import { Footer } from "./Footer";
import { HomeNavigator } from "../util/HomeNavigator";

export default function ViewOurWorks() {
  
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <Header />
      <div className=" w-full h-full tracking-wider overflow-y-auto h-full overflow-x-hidden ">
      <HomeNavigator id="whatWeDo"/>
        <div className={`bg_color_green_animation h-fit `}>
          <div className="bg-slate-900 bg-opacity-20 cssanimation sequence fadeInBottom w-full h-full  laptop:min-h-[500px] largeScreen:min-h-[500px] w-full laptop:px-8 laptop:py-10 largeScreen:px-12 largeScreen:py-14 p-4 text-white">
            <div className={`${titleContent}  py-6 border-b w-[80%]`}>
              View Our Works
            </div>
            <div className={`${textContent}  py-6 w-[75%]`}>
              Take a look at our projects to see what we've achieved
            </div>
          </div>
        </div>

        <div className="w-full h-fit p-4 px-12 flex text-gray-500 flex-col gap-5">
          <div className="px-2 w-full flex min-h-[400px] flex-col gap-4">
            <div className={`text-[#1F6D04] ${textContent} font-semibold `}>
              MERFISH
            </div>
            <div className="px-10 flex flex-col gap-3">
              <div className="flex flex-col gap-3">
                <span>
                  Our team has been hard at work developing this platform to
                  provide an intuitive and comprehensive resource for all things
                  MERFISH-related
                </span>
                <span>
                  MERF-ISH Clinical Unit and Academic Unit is the extension of
                  MERF. Several students and interns in this field from leading
                  institutions across the country have been doing their
                  internship training at MERF for several years.
                </span>
              </div>
              <div className="relative mobile:hidden left-0 largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute left-[-140px] mt-[350px] bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
              <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
            </div>
          </div>
          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute right-[-150px] mt-[200px]  border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>
            <div className="absolute right-[-150px] mt-[380px]  border-[4px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>

          </div>
          
              <div className="w-full grid grid-cols-2 largeScreen:grid-cols-3 mobile:grid-cols-1 p-4 gap-2">
                <img className="hover:border border-black hover:scale-[1.05] cursor-pointer rounded p-4"
                  src={require("../assets/projects_images/merfish/Screenshot -1.png")}
                  alt=""
                />
                <img className="hover:border border-black hover:scale-[1.05] cursor-pointer rounded p-4"
                  src={require("../assets/projects_images/merfish/Screenshot-2.png")}
                  alt=""
                />{" "}
                <img className="hover:border border-black hover:scale-[1.05] cursor-pointer rounded p-4"
                  src={require("../assets/projects_images/merfish/Screenshot-3.png")}
                  alt=""
                />{" "}
                <img className="hover:border border-black hover:scale-[1.05] cursor-pointer rounded p-4"
                  src={require("../assets/projects_images/merfish/Screenshot-4.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
