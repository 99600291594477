import React from "react";
import { card_bg_color } from "../../Style";

export default function AboutServices() {
  return (
    <div
      className={`bg-white  h-fit w-full laptop:px-20 laptop:py-10 flex flex-row flex-wrap justify-center p-2 py-6 items-center gap-10`}
    >
      <div className="cssanimation sequence fadeInBottom laptop:w-[600px] largeScreen:w-[600px] ipadMax:w-[600px] w-[100%] flex flex-col gap-4 laptop:px-20">
        <div
          className={`hover:scale-[1.1] cursor-pointer hover:bg-green-100 mobile:w-[300px]ipadMin:w-[400px] ipadMax:w-[600px] h-[100px] ${card_bg_color} rounded-[42.56px] px-8 py-2 flex flex-row items-center gap-6`}
        >
          <div className="w-10 mobile:w-12 h-10 rounded-full flex justify-center items-center bg-[#257F16] ">
            1
          </div>
          <div className="w-80">
            Trustworthy IOT service provider mainly for agriculture which helps
            to form productive agriculture sector
          </div>
        </div>
        <div
          className={`w-full hover:scale-[1.1] cursor-pointer hover:bg-green-100 h-[100px] ${card_bg_color} rounded-[42.56px] px-8 py-2 flex flex-row items-center gap-6`}
        >
          <div className="w-10 mobile:w-12 h-10 rounded-full flex justify-center items-center bg-[#257F16] ">
            2
          </div>
          <div className="w-80">
            Empowering through quality educ-ation that not only imparts
            know-ledge but also focus on personal empowerment
          </div>
        </div>{" "}
        <div
          className={`w-full hover:scale-[1.1] cursor-pointer hover:bg-green-100 h-[100px] ${card_bg_color} rounded-[42.56px] px-8 py-2 flex flex-row items-center gap-6`}
        >
          <div className="w-10 mobile:w-12 h-10 rounded-full flex justify-center items-center bg-[#257F16] ">
            3
          </div>
          <div className="w-80 mobile:w-50">
            An inclusive online marketplace where sellers connect with buyers,
            creating a platform for seamless transactions
          </div>
        </div>
      </div>
      <div className="laptop:w-[400px] largeScreen:w-[400px] h-fit   bg-gray-100 w-[300px] flex flex-row gap-4 items-center rounded-full p-16">
        <div>
          <img className="hover:scale-[1.1]" src={require("../../assets/aboutUs/servey.png")} alt="" />
        </div>
        <div className="flex flex-col gap-4">
          <img className="hover:scale-[1.1]" src={require("../../assets/aboutUs/cart.png")} alt="" />
          <img className="hover:scale-[1.1]" src={require("../../assets/aboutUs/books.png")} alt="" />
        </div>
      </div>
    </div>
  );
}
