import React from "react";
import { bg_color_green, textContent, titleContent } from "../../Style";

export default function What_we_intro() {
  return (
    <div className={`bg_color_green_animation h-fit `}>
      <div className="bg-slate-900 bg-opacity-20 w-full h-full  laptop:min-h-[500px] largeScreen:min-h-[500px] w-full laptop:px-8 laptop:py-10 largeScreen:px-12 largeScreen:py-14 p-4 text-white">
        <div
          className={`${titleContent} cssanimation sequence fadeInBottom pt-6 border-b w-[80%]`}
        >
          What We Do
        </div>
        <div
          className={`${textContent} cssanimation sequence fadeInBottom py-10 w-[60%] mobile:w-[90%] ipadMin:w-[80%]`}
        >
          We craft advanced software solutions aimed at optimizing operations,
          boosting productivity, and fostering growth for businesses across
          diverse sectors
        </div>
      </div>
    </div>
  );
}
