import {
  bottomLeftNoneRoundedSmall,
  bottomRightNoneRoundedSmall,
  textContent,
  titleContent,
  topLeftNoneRoundedSmall,
  topRightNoneRoundedSmall,
} from "../../Style";
import { ReadMore } from "../../buttons/Buttons";
import {useNavigate} from 'react-router-dom'

export const OurTeam = () => {
  const navigate=useNavigate()
  
  return (
    <div className="p-2 px-8 w-full h-fit flex text-green-900 laptop:flex-row largeScreen:flex-row flex-col-reverse flex-wrap  gap-5 justify-center">
      <div className=" laptop:w-[48%] largeScreen:w-[48%] w-full grid place-items-center laptop:border-r largeScreen:border-r  border-green-900">
        <div className="w-fit grid grid-cols-[repeat(2,1fr)] gap-1 mr-6">
          <img
            className={`aspect-auto ${bottomRightNoneRoundedSmall}`}
            src={require("../../assets/meet-team/user-1.png")}
            alt=""
            srcSet=""
          />
          <img
            className={`aspect-auto ${bottomLeftNoneRoundedSmall}`}
            src={require("../../assets/meet-team/user-2.png")}
            alt=""
            srcSet=""
          />
          <img
            className={`aspect-auto ${topRightNoneRoundedSmall}`}
            src={require("../../assets/meet-team/user-3.png")}
            alt=""
            srcSet=""
          />
          <img
            className={`aspect-auto ${topLeftNoneRoundedSmall}`}
            src={require("../../assets/meet-team/user-4.png")}
            alt=""
            srcSet=""
          />
        </div>
      </div>
      <div className=" laptop:w-[48%] largeScreen:w-[48%] h-fit w-full">
        <div className="w-full flex flex-col items-center justify-center gap-5">
          <div className={`${titleContent} text-top font-[200]`}><span className="font-semibold">Meet</span> Our <span className="font-semibold">Team</span></div>
          <div className={`${textContent} w-[90%] tracking-wider p-2  text`}>we are talented individuals who are passionate about bringing ideas to life. With a diverse range of backgrounds and skill sets we collaborate to produce effective solutions for our clients.<br/> <br /> Together, our creative team is committed to delivering impactful work that exceeds expectations</div>
       <div onClick={()=>navigate("/our-team")}><ReadMore /></div>
        </div>
      </div>
    </div>
  );
};
