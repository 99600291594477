import React, { useContext, useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./header/Header";
import { textContent, titleContent } from "../Style";
import { ActiveContext } from "../App";
import { useLocation } from "react-router-dom";
import { HomeNavigator } from "../util/HomeNavigator";

export default function TermsAndConditions() {
 
  const [active,setActive]=useContext(ActiveContext)
  const location=useLocation()
  useEffect(()=>{
    if (location.pathname==="/terms-conditions") {
     setActive("terms")
    }
  })
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <Header />
      <HomeNavigator id={localStorage.getItem("active")?"contactUsForm" :"footer"} screen={localStorage.getItem("active")?localStorage.getItem("active"):active} />

      <div className=" w-full h-full cssanimation sequence fadeInBottom text-gray-600 tracking-wider overflow-y-auto h-full overflow-x-hidden ">
        <div className="min-h-full h-fit flex flex-col mobile:mt-4  gap-5 items-center w-full mobile:px-2 px-10">
          <div className="border-b-2 border-[#104D11]  w-full mobile:w-[90%] text-center ">
            <h1 className={`${titleContent} scale-[0.6] text-top `}>
              TERMS & CONDITIONS
            </h1>
          </div>

          <div
            className={`${textContent}  mx-10 flex flex-col gap-4`}
          >
            <span>
              These Terms and Conditions govern your use of THEJA TECHNOLOGIES
              website and any services offered therein. By accessing or using
              the Website, you agree to be bound by these Terms. If you disagree
              with any part of the Terms, you may not access the Website.
            </span>
          </div>
          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute left-[-100px] mt-[-100px]  border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>
          </div>
          <div className="relative mobile:hidden left-0  largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute right-[-80px] mt-[100px] bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
              <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
            </div>
          </div>
         
          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute left-[-100px] mt-[800px] border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>
          </div>

          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute mt-[1400px] right-[-80px]  bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
              <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
            </div>
          </div>

          <ol className="list-decimal pb-4 laptop:mt-[-80px] largeScreen:mt-[-120px] ipadMin:mt-[-50px] mobile:mt-0 flex flex-col mt-[-70px] ipadMin:mt-[-60px] mobile:mt-0 gap-4 ml-6 px-10 text-[#1F6D04]">
            <li>
              <strong>Use of Website:</strong>{" "}
              <span className="text-gray-500">
                You may use the Website for lawful purposes only. You agree not
                to use the Website in any way that violates any applicable laws
                or regulations.
              </span>
            </li>
            <li>
              <strong>Intellectual Property:</strong>{" "}
              <span className="text-gray-500">
                {" "}
                All content on the Website, including but not limited to text,
                graphics, logos, images, and software, is the property of THEJA
                TECHNOLOGIES or its licensors and is protected by intellectual
                property laws. You may not use, reproduce, or distribute any
                content from the Website without prior written permission from
                THEJA TECHNOLOGIES.
              </span>
            </li>
            <li>
              <strong>User Content:</strong>{" "}
              <span className="text-gray-500">
               By submitting any content to the Website, including but not limited to comments, reviews, or feedback, you grant THEJA TECHNOLOGIES a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.
              </span>
            </li>
            <li>
              <strong>Privacy: </strong>{" "}
              <span className="text-gray-500">
              Your use of the Website is subject to THEJA TECHNOLOGIES Privacy Policy, which can be found [link to Privacy Policy]. By using the Website, you consent to the collection, use, and disclosure of your personal information in accordance with the Privacy Policy.
              </span>
            </li>
           
            <li>
              <strong> Third-Party Links: </strong>{" "}
              <span className="text-gray-500">
             The Website may contain links to third-party websites or resources. THEJA TECHNOLGIES is not responsible for the availability or content of such websites or resources and does not endorse any products, services, or content on them. You acknowledge and agree that THEJA TECHNOLGIES shall not be liable for any damages or losses arising from your use of or reliance on any third-party websites or resources.
              </span>
            </li>
            <li>
              <strong>Disclaimer of Warranties: </strong>{" "}
              <span className="text-gray-500">
              The Website is provided without any warranties of any kind, either express or implied. THEJA TECHNOLOGIES makes no warranties or representations about the accuracy or completeness of the content on the Website or the availability of any services offered therein. To the fullest extent permitted by law, THEJA TECHNOLOGIES disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
              </span>
            </li>
            <li>
              <strong> Limitation of Liability:</strong>{" "}
              <span className="text-gray-500">
              In no event shall THEJA TECHNOLOGIES be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits, lost revenue, or lost data, arising out of or in connection with your use of the Website or any services offered therein, whether based on warranty, contract or any other legal theory, even if THEJA TECHNOLOGIES has been advised of the possibility of such damages.
              </span>
            </li>
            <li>
              <strong>Indemnification:</strong>{" "}
              <span className="text-gray-500">
               You agree to indemnify and hold THEJA TECHNOLOGIES and its officers, directors, employees, and agents harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Website or any violation of these Terms.
              </span>
            </li>
            <li>
              <strong> Governing Law:</strong>{" "}
              <span className="text-gray-500">
              These Terms shall be governed by and construed in accordance with the laws of Jurisdiction, without regard to its conflict of laws principles.
              </span>
            </li>
            <li>
              <strong>Changes to Terms:</strong>{" "}
              <span className="text-gray-500">
               THEJA TECHNOLOGIES reserves the right to modify or replace these Terms at any time. It is your responsibility to review these Terms periodically for changes. Your continued use of the Website following the posting of any changes to these Terms constitutes acceptance of those changes.
              </span>
            </li>
            <li>
              <strong> Contact Us: </strong>{" "}
              <span className="text-gray-500">
             If you have any questions about these Terms, please contact us at thejatech@gmail.com
              </span>
            </li>
            <span className="text-gray-500">By using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Website.</span>
          </ol>
        </div>
        <Footer />
      </div>
    </div>
  );
}
