import React, { useState } from "react";
import { bg_color_green, take_look_menu_items, textContent, titleContent } from "../../Style";

export default function TakeALook() {
    const [active,setActive]=useState("1")
  return (
    <div
      className={`${bg_color_green}   h-fit laptop:min-h-[500px] largeScreen:min-h-[500px] w-full laptop:px-8 laptop:py-10 largeScreen:px-12 largeScreen:py-14 p-2 text-white`}
    >
       <div className='relative mobile:hidden right-[-72px]  w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center'>
      <div className='absolute mt-[-100px] right-[-20px]  border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[200px] h-[200px]'></div>
      </div>
      <div className={`${titleContent} p-2`}> Take A Look</div>
      <div className="flex flex-row justify-evenly flex-wrap gap-2 mb-4 ">
        <span className={`${take_look_menu_items} ${textContent} ${active==="1" && "text-[#FBE204] border-yellow-300"} `} onClick={()=>setActive("1")}>
          Our Commitment
        </span> 
        <span className={`${take_look_menu_items} ${textContent} ${active==="2" && "text-[#FBE204] border-yellow-300"}`}  onClick={()=>setActive("2")}>
          Our Roadmap
        </span>
        <span  className={`${take_look_menu_items} ${textContent} ${active==="3" && "text-[#FBE204] border-yellow-300"}`}  onClick={()=>setActive("3")}>
          Our Context
        </span>
        <span className={`${take_look_menu_items} ${textContent} ${active==="4" && "text-[#FBE204] border-yellow-300"}`}  onClick={()=>setActive("4")}>
          Our beliefs
        </span>
        <span className={`${take_look_menu_items} ${textContent} ${active==="5" && "text-[#FBE204] border-yellow-300"}`}  onClick={()=>setActive("5")}>
          Our Stories
        </span>
      </div>
      <div className='relative mobile:hidden right-[-72px]  w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center'>
      <div className='absolute mt-0 left-[-150px] border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[200px] h-[200px]'></div>

  <div className='absolute left-[-200px] mt-60  border-[24px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[200px] h-[200px]'>
  
  </div>
 </div>
      {
        active==="1"?
     <OurCommitments />
      :
      active==="2"?
      <OurRoadmaps />
    :
    active==="3"?
   <OurContext />
  :
  active==="4"?
 <OurBeliefs />
:
<OurStories />
      }
    
    </div>
  );
}


const OurCommitments=()=>{
  return(
    <div className="cssanimation sequence fadeInBottom grid grid-cols-2 mobile:grid-cols-1 py-4 w-full gap-10 place-items-center">
    <div className={`${textContent} font-400 px-10 w-full`}>Our commitment is to provide exceptional service, quality products, and innovative solutions to our customers while fostering a culture of sustainability, integrity, and social responsibility</div>
    <div>
        <img className="w-100 h-60" src={require('../../assets/aboutUs/our-commitment.png')} alt="" />
    </div>
  </div>
  )
}

const OurRoadmaps=()=>{
  return(
    <div className="cssanimation sequence fadeInBottom grid grid-cols-2 mobile:grid-cols-1 py-4 w-full gap-10 place-items-center">
    <div className={`${textContent} font-400 px-10`}>A strategic plan guiding our journey towards innovation, growth, and success. It serves as a comprehensive guide, aligning our efforts and resources to drive forward progress and deliver value to our stakeholders</div>
    <div>
        <img className="w-100 h-60" src={require('../../assets/aboutUs/roadmap.png')} alt="" />
    </div>
  </div>
  )
}
const OurContext=()=>{
  return(
    <div className="cssanimation sequence fadeInBottom grid grid-cols-2 mobile:grid-cols-1 py-4 w-full gap-10 place-items-center">
    <div className={`${textContent} font-400 px-10`}>A dynamic landscape where innovation, customer needs, and market trends intersect to drive the development and delivery of our products. We are committed to understanding the ever-changing needs of our customers and leveraging emerging technologies to create solutions that exceed their expectations</div>
    <div>
        <img className="w-100 h-60" src={require('../../assets/aboutUs/ourcontext.png')} alt="" />
    </div>
  </div>
  )
}

const OurBeliefs=()=>{
  return(
    <div className="cssanimation sequence fadeInBottom grid grid-cols-2 mobile:grid-cols-1 py-4 w-full gap-10 place-items-center">
  <div className={`${textContent} font-400 px-10`}>We believe in the power of technology to transform lives and empower businesses. Our values of integrity, collaboration, and continuous improvement guide everything we do as we strive to create software solutions that make a meaningful impact.</div>
  <div>
      <img className="w-100 h-60" src={require('../../assets/aboutUs/our-beliefs.png')} alt="" />
  </div>
</div>
  )
}

const OurStories=()=>{
  return(
    <div className="cssanimation sequence fadeInBottom grid grid-cols-2 mobile:grid-cols-1 py-4 w-full gap-10 place-items-center">
<div className={`${textContent} font-400 px-10`}>From overcoming challenges to celebrating milestones, our stories reflect our commitment to creating cutting-edge products that drive positive change and deliver exceptional value to our customers</div>
<div>
    <img className="w-100 h-60" src={require('../../assets/aboutUs/ovr-stories.png')} alt="" />
</div>
</div>
  )
}