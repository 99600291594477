import React, { useContext, useState } from "react";
import { bg_color_green, textContent } from "../Style";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import { ActiveContext } from "../App";
export const Footer = () => {
  const navigate = useNavigate();
  const [inputmail,setInputMail]=useState("")
  const [inputActive,setInputActive]=useState(false)


const [active, setActive] = useContext(ActiveContext);

const prevActiveScreenList=()=>{
var prevList=JSON.parse(localStorage.getItem('prevActive')|| '[]')

prevList.push(active)
localStorage.setItem('prevActive',JSON.stringify(prevList))
}


  
  const privacyHandler = () => {
    prevActiveScreenList()
 
     setActive("privacy");
    navigate("/privacy-policy");
  };
  const termsAndConditions = () => {
    prevActiveScreenList()

    setActive("terms");
    navigate("/terms-conditions");
  };
  const cookiePolicyHandler = () => {
    prevActiveScreenList()

    setActive("cookies");
    navigate("/cookie-policy");
  };
  const careerHandler = () => {
    prevActiveScreenList()

    setActive("careers");
    navigate("/careers");
  };

  const homeHandler = () => {
    setActive("home");
    navigate("/");
  };
  
  const meetTeamHandler = () => {
    prevActiveScreenList()
setActive("meetOurTeam")
    navigate("/our-team")
  };
  return (
    <div
      className={`${bg_color_green} text-white w-full h-fit flex flex-col gap-2`}
    >
     
      <div className="grid grid-cols-3 mobile:grid-cols-1 ipadMin:grid-cols-2 place-items-center items-start  p-4">
      <div className="w-fit ipadMin:w-[100%] mobile:w-[100%] ipadMax:w-[100%] p-2 px-6 uppercase flex flex-col items-center gap-5">
          <img
            className="w-[140px] h-[100px]"
            src={require("../assets/company_logo.png")}
            alt=""
            srcSet=""
          />
          <div className={`font-[Alumni_Sans] font-semibold ${textContent} text-center ipadMax:text-[1.2rem]`}>
            THEJA TECHNOLOGIES
          </div>
          <div className=" w-[330px] flex flex-col justify-between items-center gap-10 p-4">
          <div className={`border-2 border-[#FBE20494]  rounded-[60px] w-[90%] h-[50px] flex flex-row items-center gap-2 ${(inputActive||inputmail) && "bg-white"}`}>
            <input
              type="email"
              className={`w-[100%] p-2  h-[100%]  text-black focus:bg-white ${inputmail.length>0 ? "bg-white":"bg-inherit"} ${(inputActive|| inputmail) ? "text-left":" text-center"} caret-black	outline-none rounded-[60px] placeholder:text-gray-200 focus:placeholder:text-black`}
              placeholder="Enter email id to subscribe"
              onChange={(e)=>setInputMail(e.target.value)}
              onFocus={()=>setInputActive(true)}
              onBlur={()=>setInputActive(false)}
            />
           {
            (inputActive || inputmail) && 
             <span className="bg-[#FBE204] m-3 ml-0 cursor-pointer hover:scale-[1.05]  rounded-full  p-2 px-[8px] flex items-center justify-center w-fit h-fit">
            <img
              className="w-4"
              src={require("../assets/green-arrow.png")}
              alt=""
            />
          </span>
           }
          </div>
        </div>
        </div>

        <div className="w-fit mobile:w-full p-2 px-6 uppercase  flex flex-col  gap-3">
        <span className="uppercase border-b w-fit border-[#FBE204]">QUICK LINKS:</span>
          <a
           className={`hover:cursor-pointer hover:underline  hover:text-[#FBE204] ${active==='home' && "underline text-[#FBE204] "}`} onClick={homeHandler}>
            Home
          </a>
          <a  target="_blank" rel="noreferrer" onClick={privacyHandler} className={`hover:cursor-pointer hover:underline  hover:text-[#FBE204] ${active==='privacy' && "underline text-[#FBE204] "}`}>
            Privacy Statement
          </a>
          <a  target="_blank" rel="noreferrer" onClick={termsAndConditions} className={`hover:cursor-pointer hover:underline  hover:text-[#FBE204] ${active==='terms' && "underline text-[#FBE204] "}`}>
            terms & conditions
          </a>
          <a  target="_blank" rel="noreferrer" onClick={cookiePolicyHandler} className={`hover:cursor-pointer hover:underline  hover:text-[#FBE204] ${active==='cookies' && "underline text-[#FBE204] "}`}>
            cookie policy
          </a>
          <a  target="_blank" rel="noreferrer" onClick={careerHandler} className={`hover:cursor-pointer hover:underline  hover:text-[#FBE204] ${active==='careers' && "underline text-[#FBE204] "}`}>
            careers
          </a>
          <a onClick={meetTeamHandler} className={`hover:cursor-pointer hover:underline  hover:text-[#FBE204] ${active==='meetOurTeam' && "underline text-[#FBE204] "}`}>
            Meet Our Team
          </a>
        </div>

        <div className=" text-[1rem] w-fit mobile:w-full p-2 px-6 flex flex-col items-start  gap-3">
          <span className="uppercase border-b w-fit border-[#FBE204]">Contact us:</span>

          <div className="flex hover:cursor-pointer hover:text-[#FBE204] hover:underline flex-row gap-3 items-center">
            <img
              className="w-[18px] h-[12px]"
              src={require("../icons/socialMediaIcons/email.png")}
              alt=""
              srcSet=""
            />{" "}
            <span
              onClick={() =>
                window.open(
                  "mailto:support@example.com?subject=SendMail&body=Description"
                )
              }
            >
              hello@theja.in
            </span>
          </div>
          <a target="-blank"
            href="http://localhost:3001/"
            className="flex hover:text-[#FBE204] flex-row hover:cursor-pointer hover:underline gap-3 items-center"
          >
            <img
              className="w-[15px] h-[15px]"
              src={require("../icons/socialMediaIcons/browser.png")}
              alt=""
              srcSet=""
            />{" "}
            <span className="text-normal">theja.in</span>
          </a>
          <div className="flex flex-col gap-3">
            <div></div>
          <div className="mobile:text-center ipadMin:text-center uppercase uppercase border-b w-fit border-[#FBE204]">
            Connect with us:
          </div>
          <div className="flex flex-row gap-5 mt-[-4px]">
                <a
                   target="_blank" rel="noreferrer"
                  href="http://www.youtube.com/@THEJATECHNOLOGIES"
                  className="w-fit h-fit hover:scale-[1.5] hover:text-[#FBE204] cursor-pointer "
                >
                  {" "}
                  <YouTubeIcon />
                </a>
                <a
                   target="_blank" rel="noreferrer"
                  href="https://in.linkedin.com/company/theja-technologies-private-limited"
                  className="w-fit h-fit hover:scale-[1.5] hover:text-[#FBE204] cursor-pointer "
                >
                  {" "}
                  <LinkedInIcon />
                </a>
                
                <a
                   target="_blank" rel="noreferrer"
                  href="https://twitter.com/Thejatech"
                  className="w-fit h-fit hover:scale-[1.5] hover:text-[#FBE204] cursor-pointer "
                >
                  {" "}
                  <TwitterIcon />
                </a>
                <a
                   target="_blank" rel="noreferrer"
                  href="https://www.instagram.com/theja_tech/"
                  className="w-fit h-fit hover:scale-[1.5] hover:text-[#FBE204] cursor-pointer "
                >
                  {" "}
                  <InstagramIcon />
                </a>
              </div>
        </div>
        </div>
      </div>
      <div className="bg-[#022D03] p-4 w-full h-fit text-center">
        Copyright © {new Date().getFullYear()}, THEJA TECHNOLOGIES PRIVATE
        LIMITED. All rights reserved
      </div>
    </div>
  );
};
