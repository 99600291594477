import React from 'react'
import { bg_color_green, textContent, titleContent } from '../../Style'

export default function Who_We_Are() {
  return (
    <div className={`bg_color_green_animation h-fit `}>
    <div className=' bg-slate-900 cssanimation sequence fadeInBottom bg-opacity-20 w-full h-full  laptop:min-h-[500px] largeScreen:min-h-[500px] w-full laptop:px-8 laptop:py-10 largeScreen:px-12 largeScreen:py-14 p-4 text-white'>
      <div className={`${titleContent} pt-6 border-b w-[80%] `}>Who We Are</div>
      <div className={`${textContent} py-10 w-[60%] mobile:w-[90%] ipadMin:w-[80%] `}>We are dedicated to providing outstanding Services, carefully customized to align with your distinct requirements.As a leading service provider, we combine expertise, innovation, and a commitment to excellence to ensure your satisfaction</div>
    </div>
    </div>
  )
}
