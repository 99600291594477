export const titleContent="mobile:text-[1.3rem] ipadMin:text-[1.6rem] ipadMax:text-[2rem] laptop:text-[2.5rem] largeScreen:text-[3rem]";
export const textContent="mobile:text-[1rem] ipadMin:text-[1.1rem] ipadMax:text-[1.2rem] laptop:text-[1.3rem] largeScreen:text-[1.5rem]";

// colors
export const bg_color_green=" bg-[linear-gradient(108.92deg,_#093009_1.06%,_#186F19_98.94%,_#186F19_98.94%)]"//bg-[linear-gradient(106.38deg,_#2E6F17_40.53%,_#379218_60.26%)]
export const bg_light_green='bg-[#C0D09F]'
//rounded_corners for cards or images
export const topRightNoneRounded='rounded-tl-[60px] rounded-br-[60px]  rounded-tr-none rounded-bl-[60px]'
export const topLeftNoneRounded='rounded-tl-none rounded-br-[60px]  rounded-tr-[60px] rounded-bl-[60px]'
export const bottomRightNoneRounded='rounded-tl-[60px] rounded-br-none  rounded-tr-[60px] rounded-bl-[60px]'
export const bottomLeftNoneRounded='rounded-tl-[60px] rounded-br-[60px]  rounded-tr-[60px] rounded-bl-none'

//rounded_corners for cards or images childs
export const topRightNoneRoundedSmall='rounded-tl-[50px] rounded-br-[50px]  rounded-tr-none rounded-bl-[50px]'
export const topLeftNoneRoundedSmall='rounded-tl-none rounded-br-[50px]  rounded-tr-[50px] rounded-bl-[50px]'
export const bottomRightNoneRoundedSmall='rounded-tl-[50px] rounded-br-none  rounded-tr-[50px] rounded-bl-[50px]'
export const bottomLeftNoneRoundedSmall='rounded-tl-[50px] rounded-br-[50px]  rounded-tr-[50px] rounded-bl-none'


// about us

export const card_bg_color="bg-[linear-gradient(94.22deg,_rgba(10,_137,_38,_0.116)_0.42%,_rgba(235,_231,_231,_0.3074)_74.51%)] text-rgba(128, 127, 127, 1)"
export const take_look_menu_items="py-4 border-b border-gray-500 cursor-pointer hover:border-white"

export const yellow_color='[#FBE204]';
export const white_color='[#fff]'
export const black_color='[#000000]'
export const bg_gray_color='bg-[linear-gradient(209.02deg,_#F5F5F5_50%,_#DCDADA_80%)]';
export const green_color="[linear-gradient(89.99deg,_#217922_55.91%,_#0E5B0F_90.01%)]";


export const lime_green="[#73B639]"
export const leaf_green="[#197611]"

// meet our team screen styles

