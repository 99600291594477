import React, { useContext, useEffect } from "react";
import { Footer } from "./Footer";
import { Header } from "./header/Header";
import { textContent, titleContent } from "../Style";
import { HomeNavigator } from "../util/HomeNavigator";
import { ActiveContext } from "../App";
import { useLocation } from "react-router-dom";

export default function Careers() {
  const [active,setActive]=useContext(ActiveContext)
  const location=useLocation();
  useEffect(()=>{
    if (location.pathname==="/careers") {
      setActive('careers')
    }
  })

  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <Header />

      <div className=" w-full h-fit tracking-wider text-gray-600 overflow-y-auto overflow-x-hidden ">
      <HomeNavigator id={localStorage.getItem("active")?"careers" :"footer"} screen={localStorage.getItem("active")==="career"?"home":  localStorage.getItem("active")==="careers"?"about": active}/>
        <div className={`bg_color_green_animation h-fit `}>
          <div className="bg-slate-900 bg-opacity-20 cssanimation sequence fadeInBottom w-full h-fit  laptop:min-h-[500px] largeScreen:min-h-[500px] w-full laptop:px-8 laptop:py-10 largeScreen:px-12 largeScreen:py-14 p-4 text-white">
            <div className={`${titleContent}  py-6 border-b w-[80%]`}>
              Join Our Team
            </div>
            <div className={`${textContent}  py-6 w-[75%]`}>
              Welcome to the gateway of opportunities at THEJA TECHNOLOGIES! If
              you're driven by innovation, collaboration, and a passion for
              technology, then you're in the right place. Our "Join Our Team"
              page is where your journey towards a fulfilling career begins.
            </div>
          </div>
        </div>

        <div className="h-fitl w-full flex flex-col gap-5 px-14 py-4">
          <div className={`text-[#1F6D04] ${textContent}`}>
            <h1>Why Choose THEJA TECHNOLOGIES?</h1>
          </div>
          <div className="text-gray-500">
            At THEJA TECHNOLOGIES, we believe in fostering a culture of growth,
            creativity, and excellence. We're not just another IT company, we're
            a community of diverse individuals working together to make a
            difference in the world of technology. Here are a few reasons why
            joining our team is the right choice for you:
          </div>
          <ol className="list-decimal flex flex-col  gap-4 ml-6 px-10 text-[#1F6D04]">
          
            <div className="relative mobile:hidden left-0 largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute left-[-170px] mt-[-150px] bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
              <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
            </div>
          </div>
          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute right-[-150px] largeScreen:mt-[-400px] laptop:mt-[100px]  border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>
            <div className="absolute right-[-150px] largeScreen:mt-[-200px] laptop:mt-[300px]  border-[4px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>

          </div>
          
         
          <li className="mt-[-50px] ipadMin:mt-[-30px] mobile:mt-0">
              <span>Innovative Environment: </span>{" "}
              <span className="text-gray-500">
                We thrive on innovation and are constantly pushing boundaries to
                create cutting-edge solutions that make a real impact.
              </span>
            </li>
        
            <li>
              <span> Collaborative Culture: </span>{" "}
              <span className="text-gray-500">
                {" "}
                Collaboration is at the heart of everything we do. We believe in
                the power of teamwork and leverage the collective expertise of
                our team members to achieve success.
              </span>
            </li>
            <li>
              <span> Professional Growth:</span>{" "}
              <span className="text-gray-500">
                At THEJA TECHNOLOGIES, your growth matters. We provide ample
                opportunities for learning and development, empowering you to
                reach your full potential.{" "}
              </span>
            </li>
            <li>
              <span> Work-Life Balance:</span>{" "}
              <span className="text-gray-500">
                We understand the importance of maintaining a healthy work-life
                balance. That's why we offer flexible work arrangements and
                prioritize employee well-being.{" "}
              </span>
            </li>
          </ol>
          <div className={`text-[#1F6D04] ${textContent}`}>
            <h1>Join Our Team</h1>
          </div>
          <div className="text-gray-500">
          Ready to embark on an exciting journey with us? You can find our current job openings on our career page and apply directly through LinkedIn
           
          </div>
         
         </div>

        <Footer />
      </div>
    </div>
  );
}
