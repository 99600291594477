import React, { useContext, useEffect } from "react";
import { Header } from "./header/Header";
import { Footer } from "./Footer";
import { HomeNavigator } from "../util/HomeNavigator";
import { useLocation } from "react-router-dom";
import { ActiveContext } from "../App";
export default function MeetOurTeam() {
  const [active, setActive] = useContext(ActiveContext);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "our-team") {
      setActive("meetOurTeam");
    }
  });

  return (
    <div className="w-full h-full fixed flex flex-col">
      <div className="w-full h-fit">
        <Header />
      </div>
      <div className="w-full overflow-y-auto h-full overflow-x-hidden">
        <OurTeam active={active} />
        <Footer />
      </div>
    </div>
  );
}

export const OurTeam = ({ active }) => {
  return (
    <div className="w-full grid place-items-center">
      <HomeNavigator
        id={active === "home" ? "meetTeam" : "footer"}
        screen={active}
      />
      <div className="border-b border-[#104D11] w-[90%] px-10 h-fit text-center text-[56px]">
        <span className="text-top font-[700]">Meet</span>
        <span className="text-top font-[400]"> Our </span>
        <span className="text-top font-[700]">Team</span>
      </div>
      <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
        <div className="absolute left-[-30px]  top-[300px] border-[24px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]"></div>
        <div className="absolute mt-40 left-[-30px] top-[250px] border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]"></div>
      </div>{" "}
      <div className="relative mobile:hidden right-[-72px] largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
        <div className="absolute right-0   border-[24px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]"></div>
        <div className="absolute mt-40 right-5 border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]"></div>
      </div>
      <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
        <div className="absolute right-[-30px]  top-[580px] border-[24px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[120px] h-[120px]"></div>
        <div className="absolute mt-40 right-[-30px] top-[500px] border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[120px] h-[120px]"></div>
      </div>
      <div className="w-[75%] z-[1] cssanimation sequence fadeInBottom min-h-[300px]  h-fit p-4 grid grid-cols-3 ipadMax:grid-cols-2 ipadMin:grid-cols-2 mobile:grid-cols-1 gap-5 place-items-center">
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/riyaz.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Riyaz Syed</strong>
            <span>Scrum Master & Project Manager</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/hemanth.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Hemanth Kumar Reddy B</strong>
            <span> Software Engineer</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/pavankalyan.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Pavan Kalyan Reddy M</strong>
            <span> DevOps Engineer</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/rakeshArja.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Arja Sai Rakesh</strong>
            <span>DevOps Engineer</span>
          </div>
        </div>

        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/madhusudhan.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Madhusudhan Reddy C</strong>
            <span> IoT Engineer</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/sneha.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Sneha Mokala</strong>
            <span> Software Engineer</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/navya.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Araveeti Navyasree</strong>
            <span>UI/UX Designer</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/mrunali.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Mrunali Newarekar</strong>
            <span>UI/UX Designer</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/Yashvani.jpg")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Yashvani Balaga</strong>
            <span>Software Engineer</span>
          </div>
        </div>
        <div className={`${cardDiv}`}>
          <div className={`${imageDiv}`}>
            <img src={require("../assets/meet-team/Priyanka.png")} alt="" />
          </div>
          <div className={`${name_role_div}`}>
            <strong>Priyanka Alluri</strong>
            <span>Software Engineer</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const cardDiv =
  "bg_green w-fit h-fit  flex flex-col shadow-md cursor-pointer rounded-lg hover:scale-[1.02] p-4 gap-2 items-center ";
export const imageDiv =
  "bg_imag_green w-[250px] h-fit min-h-[200px] border p-4 pb-0 flex flex-row ";
export const imageDiv_imgTag = "";
export const name_role_div = "flex flex-col  items-center";
