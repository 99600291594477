import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveContext } from "../App";

export const HomeNavigator = ({ id, screen }) => {
  const navigate = useNavigate();
  const [active, setActive] = useContext(ActiveContext);

  const homeHandler = () => {
    localStorage.setItem("idScroll", id);
    if (screen === "about") {
      setActive("about");
      navigate("/about-theja.in");
     
    }
    else if(screen==="contactus"){
      setActive("contactus")
      navigate("/contact-us");
    }
    else {
      setActive("home");
      navigate(`/`);
     
    }

  };

  const listPrev = JSON.parse(localStorage.getItem("prevActive") || "[]");
  const len = listPrev.length - 1;

  const prevListRemoveLastIndex = () => {
    listPrev.map((key, index) => {
      if (index===listPrev.length-1) {
        listPrev.pop();
    return  localStorage.setItem("prevActive", JSON.stringify(listPrev));
      }
    });
  };

  const prevHomeHandler = () => {
    localStorage.setItem("idScroll", id);
    if (listPrev[len] === "home") {
      prevListRemoveLastIndex();

     return navigate("/");
    } else if (listPrev[len] === "about") {
      prevListRemoveLastIndex();

      return navigate("/about-theja.in");
    }  else if (listPrev[len] === "contactus") {
      prevListRemoveLastIndex();

      return  navigate("/contact-us");
    } else if (listPrev[len] === "privacy") {
      prevListRemoveLastIndex();

      return  navigate("/privacy-policy");
    } else if (listPrev[len] === "terms") {
      prevListRemoveLastIndex();

      return  navigate("/terms-conditions");
    } else if (listPrev[len] === "cookies") {
      prevListRemoveLastIndex();

      return navigate("/cookie-policy");
    } else if (listPrev[len] === "careers") {
      prevListRemoveLastIndex();

      return navigate("/careers");
    }
    else if (listPrev[len] === "meetOurTeam") {
      prevListRemoveLastIndex();

      return navigate("/our-team");
    }
  };
  return (
    <div
      className={`w-[40px]  rounded-full h-[40px]  cursor-pointer fixed top-[12vh] grid place-items-center left-3 ${"bg-slate-100"}`}
      onClick={() =>
        JSON.parse(localStorage.getItem("prevActive") || "[]").length > 0
          ? prevHomeHandler()
          : homeHandler()
      }
    >
      <NavigateBeforeIcon className="hover:scale-[1.3] relative" />
    </div>
  );
};
