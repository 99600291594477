import React, { useContext, useEffect } from "react";
import { Home } from "./Home";
import { Ideas } from "./Ideas";
import OurService from "./OurService";
import { OurTeam } from "./OurTeam";
import { ProjectsOfCompany } from "./ProjectsOfCompany";
import { OurCulture } from "./OurCulture";
import { Footer } from "../Footer";
import { Header } from "../header/Header";
import { ActiveContext } from "../../App";
import { useLocation } from "react-router-dom";

export default function HomeScreen() {
  const [active,setActive]=useContext(ActiveContext)
  const location=useLocation();
  useEffect(()=>{
    if (location.pathname==="/"||location.pathname==="/#/") {
      setActive('home')
    }
  })

  useEffect(()=>{
    if (localStorage.getItem('idScroll')!==null) {
     document.getElementById(localStorage.getItem('idScroll')?localStorage.getItem('idScroll'):"").scrollIntoView({ behavior: "instant" })
    }
      localStorage.removeItem('idScroll');
  },[])
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      <div className=" w-full h-full tracking-wider overflow-y-auto h-full overflow-x-hidden ">
        <div id="whatWeDo">
          <Home />
        </div>
        <div>
          {" "}
          <Ideas />
        </div>
        <div>
          <OurService />
        </div>
        <div id="meetTeam">
          <OurTeam />
        </div>
        <div>
          <ProjectsOfCompany />
        </div>
        <div id="careers">
          <OurCulture />
        </div>
        <div id="footer">
          {" "}
          <Footer />
        </div>{" "}
      </div>
    </div>
  );
}
