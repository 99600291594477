import React from 'react'
import { bg_gray_color, textContent, titleContent } from '../../Style'
import { JoinUs } from '../../buttons/Buttons'
import Person3Icon from '@mui/icons-material/Person3';
export default function OurPeople() {
  return (
    <div className='w-full cssanimation sequence fadeInBottom h-fit bg-white mobile:text-center flex flex-col gap-5 items-center'>
    <div className={`about_our_people_bg w-full  `}>
      <div className='bg-slate-900 bg-opacity-40 w-full h-[500px] laptop:px-20 laptop:py-10 largeScreen:px-20 largeScreen:py-8 flex flex-col p-2 py-6  gap-10 text-white font-bold'>
       <div className={`${titleContent} `}>Our People</div>
       <div className={`w-[600px] h-fit pl-20 mobile:pl-0 mobile:w-full ipadMin:pl-0 ipadMin:w-full  `}>Our people are passionate about what they do, driven by a shared commitment to delivering exceptional results. We value creativity, initiative, and a collaborative spirit, and these qualities define our team's approach to overcoming challenges and seizing opportunities</div>
      <div className='mobile:text-center w-full pl-20 mobile:pl-0 mt-[-20px] grid mobile:place-items-center'><JoinUs /></div>
      </div>
    </div>

   <div className='flex flex-col items-center w-full  mt-[-160px] mobile:mt-[-150px]'>
   <div className={`text-left w-[60%] font-bold mobile:text-center ${titleContent} relative text-white `}>Meet Our Founders</div>
    <div className={`h-fit w-[90%] flex flex-row flex-wrap gap-5 justify-center p-4 border-b-2 text-[0.8rem]`}>
      
      <div className={`relative hover:scale-[1.1] cursor-pointer p-4 ${bg_gray_color} h-[280px] w-[240px] flex flex-col gap-5 items-center`}>
        <img className='w-[200px] h-[220px]' src={require('../../assets/company_persons/download.png')} alt="" />
      {/* <div className='h-full'>  <Person3Icon /></div> */}
        <div>CHAIRMAN - RAMA DEVI</div>
      </div>
      <div className={`relative hover:scale-[1.1] cursor-pointer p-4 h-[280px] w-[240px] flex flex-col ${bg_gray_color} gap-5 items-center`}>
        <img className='w-[200px] h-[220px]' src={require('../../assets/company_persons/ramadevi.png')} alt="" />
        <div>MD & CEO - THEJA</div>
      </div>
      <div className={`relative hover:scale-[1.1] cursor-pointer p-4 h-[280px] ${bg_gray_color} w-[240px] text-center flex flex-col gap-5 items-center`}>
        <img className='w-[200px] h-[220px]' src={require('../../assets/company_persons/jithendra.png')} alt="" />
        <div >CTO & OPERATIONS - JITHENDRA REDDY</div>
      </div>
    </div>
   </div>
    <div className='p-4 pb-10 flex flex-col items-center gap-5 '>
      <div className={`text ${textContent} mobile:text-center`}>We're Are a Startup Officially Recognized By StartupIndia Programme</div>
      <div>
        <img className='hover:scale-[1.01]' src={require('../../assets/company_persons/company_certificate.png')} alt="" srcSet="" />
      </div>
    </div>
  
    </div>
  )
}
