import React from "react";
import { textContent, titleContent, white_color } from "../../Style";

export default function Services() {
  return (
    <div className="w-full h-fit flex flex-col gap-5">
      <div
        className={`bg-[linear-gradient(90deg,_#5AD023_12.31%,_#0E8C0B_100%)] rounded-tr-full w-fit  px-10 py-4 pr-10 text-[#fff] py-2 text-[1.8rem] `}
      >
        Our Services
      </div>
      <div className="w-full grid place-items-center">
        <div className="px-10 w-fit gap-10 grid grid-cols-3 mobile:grid-cols-1 place-items-center p-2">
          <div className="flex flex-col hover:scale-[1.1] hover:cursor-pointer items-center border rounded-xl border-[#315C2E] w-fit gap-4 p-2 px-8 ">
            <strong >IOT Services</strong>
            <img
              className="w-[220px]   h-[200px]"
              src={require("../../assets/whatWeDo/IOt_service.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col hover:scale-[1.1] hover:cursor-pointer items-center border rounded-xl border-[#315C2E] w-fit gap-4 p-2 px-8 ">
            <strong>Cloud Computing</strong>
            <img
              className="w-[220px]  h-[200px]"
              src={require("../../assets/whatWeDo/cloud_computing.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col hover:scale-[1.1] hover:cursor-pointer items-center border rounded-xl border-[#315C2E] w-fit gap-4 p-2 px-8 ">
            <strong>Devops</strong>
            <img
              className="w-[220px]  h-[200px]"
              src={require("../../assets/whatWeDo/devops.png")}
              alt=""
            />
          </div>

          <div className="flex flex-col hover:scale-[1.1] hover:cursor-pointer items-center border rounded-xl border-[#315C2E] w-fit gap-4 p-2 px-8 ">
            <strong>Web Development</strong>
            <img
              className="w-[220px]  h-[200px]"
              src={require("../../assets/whatWeDo/web-development.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col hover:scale-[1.1] hover:cursor-pointer items-center border rounded-xl border-[#315C2E] w-fit gap-4 p-2 px-8 ">
            <strong>Software Consultancy</strong>
            <img
              className="w-[220px]  h-[200px]"
              src={require("../../assets/whatWeDo/software_consultancy.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col hover:scale-[1.1] hover:cursor-pointer items-center border rounded-xl border-[#315C2E] w-fit gap-4 p-2 px-8 ">
            <strong>UI/UX Design</strong>
            <img
              className="w-[220px]  h-[200px]"
              src={require("../../assets/whatWeDo/Ui_Ux_design.png")}
              alt=""
            />
          </div>
          <div className="flex flex-col hover:scale-[1.1] hover:cursor-pointer items-center border rounded-xl border-[#315C2E] w-fit gap-4 p-2 px-8 ">
            <strong>IOT Products</strong>
            <img
              className="w-[220px] h-[200px]"
              src={require("../../assets/whatWeDo/IOT_products.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
