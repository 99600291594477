import React from "react";
import {
  bg_color_green,
  bg_light_green,
  textContent,
  titleContent,
} from "../../Style";

export const ProjectsOfCompany = () => {
  return (
    <div className="w-[100%]  h-fit py-6  text-white flex flex-col items-center">
      <div
        className={`${bg_color_green} text-center w-full h-fit fex flex-col gap-5 p-4 justify-center items-center`}
      >
        <div className={`${titleContent} font-[200] tracking-wide `}>
          Join <span className="font-semibold">Theja Technologies</span> To Succeed!
        </div>
        <div className={`${textContent} tracking-wider`}>
          Follow the example of our customers and elevate your business to new
          heights.
        </div>
      </div>
      <div
        className={`${bg_light_green} z-[-1] w-full h-fit flex flex-row gap-5 py-8 justify-center items-center`}
      >
         <div className="flex  flex-row gap-4">
         <div className="flex logo-slide flex-row items-center gap-20">
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/ecohex-logo.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/merfsh-logo.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/project2hire.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/algonomy.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/quardrant2.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/FSTeck_LLC.jpg")}
              alt=""
              srcSet=""
            />
             <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/ecohex-logo.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/merfsh-logo.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/project2hire.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/algonomy.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/quardrant2.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/FSTeck_LLC.jpg")}
              alt=""
              srcSet=""
            />
             <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/ecohex-logo.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/merfsh-logo.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/project2hire.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/algonomy.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/quardrant2.png")}
              alt=""
              srcSet=""
            />
            <img
              className="h-[70px] bg-white p-1"
              src={require("../../assets/projects_logos/FSTeck_LLC.jpg")}
              alt=""
              srcSet=""
            />
          </div>
          
         
         </div>
    
      
      </div>
      <div className=" w-full h-fit flex flex-col gap-5 p-4">
        <div className={`${titleContent} text font-[700] text-center`}>
          Why to choose us
        </div>
        <div className="w-full flex gap-12 gap-y-5 flex-row  justify-center items-center flex-wrap">
          <div className="bg-[#C0D09F] w-[260px] border flex flex-row gap-5 items-center h-[80px] rounded-[60px]">
            <span className="grid place-items-center p-3 w-22  bg-white rounded-full h-[100%] ml-[-2px]">
              <img
                src={require("../../assets/WhyChooseUsLogos/services.png")}
                alt=""
                srcSet=""
              />
            </span>{" "}
            <span className="text-black font-[600] w-auto pr-5 text-center">
              Value Added Services
            </span>
          </div>
          <div className="bg-[#C0D09F] w-[260px] border flex flex-row gap-5 items-center h-[80px] rounded-[60px]">
            <span className="grid place-items-center p-3 w-20  bg-white rounded-full h-[100%] ml-[-2px]">
              <img
                src={require("../../assets/WhyChooseUsLogos/QualityService.png")}
                alt=""
                srcSet=""
              />
            </span>{" "}
            <span className="text-black font-[600] w-auto pr-5 text-center">
            Quality Service
            </span>
          </div> 
          <div className="bg-[#C0D09F] w-[260px] border flex flex-row gap-5 items-center h-[80px] rounded-[60px]">
            <span className="grid place-items-center p-3 w-20  bg-white rounded-full h-[100%] ml-[-2px]">
              <img
                src={require("../../assets/WhyChooseUsLogos/costEffective.png")}
                alt=""
                srcSet=""
              />
            </span>{" "}
            <span className="text-black font-[600] w-auto pr-5 text-center">
            Cost Effective
            </span>
          </div> <div className="bg-[#C0D09F] w-[260px] border flex flex-row gap-5 items-center h-[80px] rounded-[60px]">
            <span className="grid place-items-center p-3 w-20  bg-white rounded-full h-[100%] ml-[-2px]">
              <img
                src={require("../../assets/WhyChooseUsLogos/support.png")}
                alt=""
                srcSet=""
              />
            </span>{" "}
            <span className="text-black font-[600] w-auto pr-5 text-center">
            24/7 Support
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
