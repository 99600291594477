import React from "react";
import { JoinUs } from "../../buttons/Buttons";
import { textContent, titleContent } from "../../Style";

export const OurCulture = () => {
  return (
    <div className="cssanimation sequence p-2 fadeInBottom w-[100%] text-green-800 h-fit flex laptop:flex-row  largeScreen:flex-row flex-col-reverse flex-wrap">

      <img
        className="mobile:w-[100%]    rounded-xl ipadMin:w-[100%] ipadMax:w-[100%]  w-[60%] h-[450px] mobile:h-[300px] largeScreen:h-[650px]"
        src={require("../../assets/culture_logo.png")}
        alt=""
        srcSet=""
      />
      <div className="mobile:w-[100%] ipadMin:w-[100%] ipadMax:w-[100%] w-[40%] flex flex-col gap-5 pt-10 mobile:pt-0 p-2 items-center">
        <h1 className={`${titleContent} text font-[200]`}>
          Our <span className="font-semibold">Culture</span>
        </h1>
        <div className="w-[100%] flex flex-col items-center">
          <p className={`${textContent} text w-[90%] text-center`}>
            We Combine technology with human ingenuity to solve some of the
            works biggest challenges. When u join us, the possibilities are
            endless.
          </p>
        </div>
        <div className=" laptop:mt-20 largeScreen:mt-20">
          <JoinUs screen="home"/>
        </div>
      </div>
    </div>
  );
};
