import React, { useContext, useEffect } from "react";
import { Header } from "./header/Header";
import { textContent, titleContent } from "../Style";
import { Footer } from "./Footer";
import { ActiveContext } from "../App";
import { useLocation } from "react-router-dom";
import { HomeNavigator } from "../util/HomeNavigator";

export default function CookiePolicy() {

  const [active,setActive]=useContext(ActiveContext)
  const location=useLocation();
  useEffect(()=>{
    if (location.pathname==="/cookie-policy") {
      setActive('cookies')
    }
  })

  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <Header />
      <HomeNavigator id="footer"/>

      <div className=" w-full h-full tracking-wider text-gray-600 cssanimation sequence fadeInBottom  overflow-y-auto h-full overflow-x-hidden ">
        <div className="min-h-full h-fit flex flex-col mobile:mt-4  gap-5 items-center w-full mobile:px-2 px-10">
          <div className="border-b-2 border-[#104D11]  w-full mobile:w-[90%] text-center ">
            <h1 className={`${titleContent} scale-[0.6] text-top `}>COOKIE POLICY</h1>
          </div>

          <div
            className={`${textContent}  mx-10 flex flex-col gap-4`}
          >
            <span>
              This Cookie Policy explains how THEJA TECHNOLOGIES uses cookies
              and similar technologies on our website Your Website URL.
            </span>

            <span>
              By using the Service, you consent to the use of cookies and
              similar technologies in accordance with this Cookie Policy.
            </span>
          </div>
          <div className="relative mobile:hidden left-0 largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute left-[-100px] mt-[-800px] bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
              <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
            </div>
          </div>
          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute right-[-100px] mt-[400px]  border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>
          </div>
          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute left-[-100px] mt-[1800px] border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>
          </div>

          <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
            <div className="absolute mt-[2600px] right-[-80px]  bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
              <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
            </div>
          </div>

          <div
            className={`w-full text-left px-10 laptop:mt-[-80px] largeScreen:mt-[-120px] ipadMin:mt-[-50px] mobile:mt-0 uppercase ${textContent} font-bold laptop:text-[1.8rem] text-[#1F6D04]`}
          >
            <h1>What are Cookies</h1>
          </div>
          <div
            className={`${textContent}  mx-10 flex flex-col gap-4`}
          >
            <span>
              Cookies are small pieces of text sent by your web browser by a
              website you visit. A cookie file is stored in your web browser and
              allows the Service or a third-party to recognize you and make your
              next visit easier and the Service more useful to you.
            </span>
            <span>
              Cookies can be "persistent" or "session" cookies. Persistent
              cookies remain on your personal computer or mobile device when you
              go offline, while session cookies are deleted as soon as you close
              your web browser.
            </span>

            <div className="text-[#1F6D04] font-semibold ">
              <h1>How THEJA TECHNOLOGIES Uses Cookies</h1>
            </div>
            <div className="flex flex-col gap-4">
              <span>
                When you use and access the Service, we may place a number of
                cookies files in your web browser. We use cookies for the
                following purposes:
              </span>
              <ul className="list-disc ml-10">
                <li>To enable certain functions of the Service</li>
                <li>To provide analytics</li>
                <li>To store your preferences</li>
                <li>
                  To enable advertisements delivery, including behavioral
                  advertising
                </li>
              </ul>
              <span>
                We use both session and persistent cookies on the Service and we
                use different types of cookies to run the Service:{" "}
              </span>
              <ul className="list-disc ml-10">
                <li>
                  Essential cookies: We may use essential cookies to
                  authenticate users and prevent fraudulent use of user
                  accounts.
                </li>
                <li>
                  Third-party cookies: In addition to our own cookies, we may
                  also use various third-parties cookies to report usage
                  statistics of the Service, deliver advertisements on and
                  through the Service, and so on.
                </li>
              </ul>
            </div>
            <div className="text-[#1F6D04] font-semibold ">
              <h1>Your Choices Regarding Cookies</h1>
            </div>
            <div className="flex flex-col">
              <span>
                If you'd like to delete cookies or instruct your web browser to
                delete or refuse cookies, please visit the help pages of your
                web browser.
              </span>
              <span>
                Please note, however, that if you delete cookies or refuse to
                accept them, you might not be able to use all of the features we
                offer, you may not be able to store your preferences, and some
                of our pages might not display properly.
              </span>
            </div>

            <div className="text-[#1F6D04] font-semibold ">
              <h1>Changes to Our Cookie Policy</h1>
            </div>
            <div className="flex flex-col">
              <span>
                We may update our Cookie Policy from time to time. We will
                notify you of any changes by posting the new Cookie Policy on
                this page.{" "}
              </span>
              <span>
                You are advised to review this Cookie Policy periodically for
                any changes. Changes to this Cookie Policy are effective when
                they are posted on this page.{" "}
              </span>
            </div>

            <div className="text-[#1F6D04] font-semibold ">
              <h1>Contact Us</h1>
            </div>
            <div className="pb-4">
              <span>
                {" "}
                If you have any questions about this Cookie Policy, please
                contact us:
              </span>{" "}
              <ul className="list-disc ml-10">
                <li>
                  By email:{" "}
                  <span
                    className="underline hover:text-black cursor-pointer"
                    onClick={() =>
                      window.open(
                        "mailto:support@example.com?subject=SendMail&body=Description"
                      )
                    }
                  >
                    hello@theja.in
                  </span>{" "}
                </li>
                <li>
                  By visiting this page on our website:{" "}
                  <a  target="_blank" rel="noreferrer"className="underline hover:text-black cursor-pointer" href="https://theja.in">https://theja.in</a>
                </li>
              </ul>

              <span>This Cookie Policy was last updated on 23-04-2024.</span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
