import React, { useContext, useEffect } from "react";
import { Header } from "../header/Header";
import WhoWeAre from "./Who_We_Are";
import AboutServices from "./AboutServices";
import TakeALook from "./TakeALook";
import { Footer } from "../Footer";
import OurPeople from "./OurPeople";
import { ActiveContext } from "../../App";
import { useLocation } from "react-router-dom";

export default function AboutUs() {
  const location = useLocation();
  const [active, setActive] = useContext(ActiveContext);
  useEffect(() => {
    if (location.pathname === "/about-theja.in") {
      setActive("about");
    }
  });
  useEffect(() => {
    if (localStorage.getItem("idScroll") !== null) {
      document
        .getElementById(
          localStorage.getItem("idScroll")
            ? localStorage.getItem("idScroll")
            : ""
        )
        .scrollIntoView({ behavior: "instant" });
    }
    localStorage.removeItem("idScroll");
  }, []);
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      <div className="w-full h-full tracking-wider z-[-1] overflow-y-auto h-full overflow-x-hidden">
        <div>
          {" "}
          <WhoWeAre />
        </div>
        <div>
          {" "}
          <AboutServices />
        </div>
        <div>
          {" "}
          <TakeALook />
        </div>
        <div id="careers">
          <OurPeople />
        </div>
        <div id="footer">
          {" "}
          <Footer />
        </div>
      </div>
    </div>
  );
}
